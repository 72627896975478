import * as bootstrap from 'bootstrap';
import axios from 'axios';
window.bootstrap = bootstrap;
AOS.init();

if( document.getElementById('h-section-4') ) {

	let placeButtons = document.getElementsByClassName('place-selector');

	for( let button of placeButtons ) {

		button.addEventListener('click', ( event ) => {

			let placeSelection = event.currentTarget.dataset.place;

			if( !event.currentTarget.classList.contains( 'active') ) {
				button.classList.add( 'active' );
			}

			let restButtons = document.querySelectorAll( `:not([data-place="${placeSelection}"])` )
			for( let restBtn of restButtons ) {
				restBtn.classList.remove( 'active' );
			}

			selectPlaceImages( placeSelection );

		});

	}


	document.getElementById('place-list-selector').addEventListener('change', ( event ) => {

		let placeButtons = document.querySelectorAll( `[data-place="${event.currentTarget.value}"].place-selector` );
		if( placeButtons[0] ) {
			placeButtons[0].click();
		}

	});


	function selectPlaceImages( placeSelection ) {

		if( placeSelection != 'todos' ) {
			let placeImages = document.querySelectorAll( `:not([data-place-img="${placeSelection}"]).place-image-section` )
			for( let placeImg of placeImages ) {
				placeImg.classList.add( 'hide' );
			}

			let myPlaceImages = document.querySelectorAll( `[data-place-img="${placeSelection}"].place-image-section` )
			for( let placeImg of myPlaceImages ) {
				placeImg.classList.remove( 'hide' );
			}

		} else {

			let placeImages = document.querySelectorAll( `.place-image-section` )
			for( let placeImg of placeImages ) {
				placeImg.classList.remove( 'hide' );
			}

		}

	}

}


if( document.getElementById('place-dynamic-content') ) {

	if( document.getElementById('lista-espera-button') ) {
		document.getElementById( 'lista-espera-button' ).addEventListener('click', ( event ) => {
			document.getElementById( 'schedule-content' ).classList.remove( 'show' );
			document.getElementById( 'agenda-content' ).classList.remove( 'show' );
			document.getElementById( 'lista-espera-content' ).classList.toggle( 'show' );
		});

		document.getElementById( 'lista-espera-close-button' ).addEventListener('click', ( event ) => {
			document.getElementById( 'lista-espera-content' ).classList.remove( 'show' );
		});
	}

	document.getElementById( 'agenda-button' ).addEventListener('click', ( event ) => {
		document.getElementById( 'schedule-content' ).classList.remove( 'show' );
		if( document.getElementById('lista-espera-button') )
			document.getElementById( 'lista-espera-content' ).classList.remove( 'show' );
		document.getElementById( 'agenda-content' ).classList.toggle( 'show' );
	});

	document.getElementById( 'agenda-close-button' ).addEventListener('click', ( event ) => {
		document.getElementById( 'agenda-content' ).classList.remove( 'show' );
	});

	document.getElementById( 'schedule-button' ).addEventListener('click', async ( event ) => {
		document.getElementById( 'agenda-content' ).classList.remove( 'show' );
		if( document.getElementById('lista-espera-button') )
			document.getElementById( 'lista-espera-content' ).classList.remove( 'show' );
		document.getElementById( 'schedule-content' ).classList.toggle( 'show' );

        placeSelected = event.currentTarget.getAttribute('data-name');
        toggleComponent(event.currentTarget.getAttribute('data-id'));

	});

	document.getElementById( 'schedule-close-button' ).addEventListener('click', ( event ) => {
		document.getElementById( 'schedule-content' ).classList.remove( 'show' );
	});

}

if( document.getElementById('vev-section-3') ) {

	let vevButtons = document.getElementsByClassName('vev-selector');

	for( let button of vevButtons ) {

		button.addEventListener('click', ( event ) => {

			let vevSelection = event.currentTarget.dataset.vevsection;

			if( !event.currentTarget.classList.contains( 'active') ) {
				button.classList.add( 'active' );
			}

			let restButtons = document.querySelectorAll( `:not([data-vevsection="${vevSelection}"])` )
			for( let restBtn of restButtons ) {
				restBtn.classList.remove( 'active' );
			}

			selectSection( vevSelection );

		});

	}


	function selectSection( vevSelection ) {


		let vevSections = document.querySelectorAll( `.vev-section` )
		for( let section of vevSections ) {
			section.classList.remove( 'active' );
		}

		document.getElementById( 'vev-section-'+vevSelection ).classList.add( 'active' );

	}

}


if( document.getElementById('pide-informacion') ) {

	document.getElementById('more-info-selector').addEventListener('change', ( event ) => {

		let iframeElement = document.getElementById( `${event.currentTarget.value}form` );

		let forms = document.querySelectorAll( '.iframe-form' );
		for( let f of forms ) {
			f.classList.add( 'd-none' );
		}

		if( iframeElement ) {
			iframeElement.classList.remove( 'd-none' );
		}

	});


}


// HORARIOS*****************************
let currentWeekDate = new Date();
let placeSelected = -99;
let placeNameSelected = '';
let datesPDFSelected;

if( document.getElementById('h-section-4') ) {

    document.getElementById('place-schedule-selector').addEventListener('change', ( event ) => {

		let element = event.currentTarget.value;
        placeSelected = event.currentTarget.value;
        toggleComponent(element);


	});

}

async function toggleComponent(value) {

    const component = document.getElementById('schedule-component');
    const actualWeek = await getMondayAndSunday(currentWeekDate);

    switch( value ) {
        case '-99':
            placeNameSelected = '';
            component.classList.add('d-none'); // Oculta el componente
            break;
        case '5242':
            placeNameSelected = 'Alcántara';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '5891':
            placeNameSelected = 'Castellana';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '5402':
            placeNameSelected = 'Piscinas Sevilla';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '5212':
            placeNameSelected = 'La Rosaleda';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '0661':
            placeNameSelected = 'Ekke';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '0623':
            placeNameSelected = 'Illes Aiguablava';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '0508':
            placeNameSelected = 'Illes Calvià';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '0314':
            placeNameSelected = 'Illes Marratxí';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
        case '5337':
            placeNameSelected = 'Sant Jordi';
            component.classList.remove('d-none'); // Muestra el componente
            showSpinner();
            loadScheduleData( actualWeek, value );
            break;
    }

}

async function loadScheduleData( dates, idinstallation ) {

    datesPDFSelected = dates;

    dates.sundayISO = await addDaysToDate(dates.sundayISO, 1);

    axios.get(`/api/provis/collectiveclasses-between-dates?installationid=${idinstallation}&startdatetime=${ dates.mondayISO }&endDatetime=${ dates.sundayISO }`) // Ajusta el endpoint según tu configuración
        .then(response => {
            console.log(response);
            const scheduleData = response.data; // Datos devueltos por la API
            updateSchedule(scheduleData);
            populateGroupSquares(scheduleData);
            populateMobileSchedule(scheduleData);
            hideSpinner();
        })
        .catch(error => {
            console.error('Error al cargar el horario:', error);
        });
}

function updateSchedule( scheduleData) {
    const scheduleContainer = document.getElementById('schedule-container');

    if (!scheduleContainer) return;

    // Limpiar el contenido existente
    scheduleContainer.innerHTML = '';

    // Crear un mapa para organizar las actividades por horas y días
    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    const scheduleMap = {};

    scheduleData.forEach(activity => {
        // Verificar y corregir los formatos de las fechas
        const correctedStartTime = activity.horaDeInicio ? activity.horaDeInicio.replace(/=>/g, ':') : null;
        const correctedEndTime = activity.horaDeFin ? activity.horaDeFin.replace(/=>/g, ':') : null;

        const startTime = correctedStartTime ? new Date(correctedStartTime) : null;
        const endTime = correctedEndTime ? new Date(correctedEndTime) : null;

        if (!startTime || isNaN(startTime.getTime())) {
            console.error('Hora de inicio inválida:', activity.horaDeInicio);
            return;
        }

        if (!endTime || isNaN(endTime.getTime())) {
            console.error('Hora de fin inválida:', activity.horaDeFin);
        }

        const hour = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        let dayIndex = startTime.getDay(); // 0 = Domingo, 1 = Lunes, ..., 6 = Sábado

        // Ajustar el índice para que 1 sea Lunes y 0 (Domingo) sea el último día
        dayIndex = dayIndex === 0 ? 6 : dayIndex - 1;

        const day = daysOfWeek[dayIndex];

        if (!scheduleMap[hour]) {
            scheduleMap[hour] = {};
        }

        if (!scheduleMap[hour][day]) {
            scheduleMap[hour][day] = [];
        }

        scheduleMap[hour][day].push({
            nombre: activity.nombre,
            color: activity.color,
            nombreZona: activity.nombreZona,
            descripcion: activity.descripcion,
            dayWeek: daysOfWeek[(startTime.getDay() === 0 ? 6 : startTime.getDay() - 1)],
            horaInicio: startTime ? startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            horaFin: endTime ? endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A'
        });
    });

    // Generar las filas de la tabla
    Object.keys(scheduleMap).sort().forEach(hour => {
        const row = document.createElement('tr');

        // Columna de la hora
        const hourCell = document.createElement('td');
        hourCell.textContent = hour;
        hourCell.classList.add('text-l-green');
        row.appendChild(hourCell);

        // Columnas de los días
        daysOfWeek.forEach(day => {
            const dayCell = document.createElement('td');
            const activities = scheduleMap[hour][day];

            if (activities && activities.length > 0) {
                activities.forEach(activity => {
                    const activityDiv = document.createElement('div');
                    activityDiv.classList.add('activity-div');
                    activityDiv.style.padding = '5px';
                    activityDiv.style.marginBottom = '5px';

                    // const startTime = new Date(activity.horaInicio.replace(/=>/g, ':'));
                    // const endTime = new Date(activity.horaFin.replace(/=>/g, ':'));
                    // const dayOfWeek = daysOfWeek[(startTime.getDay() === 0 ? 6 : startTime.getDay() - 1)];

                    activityDiv.innerHTML = `
                        <strong style="color: ${intToHexColor(activity.color)};">${activity.nombre}</strong><br>
                        <span style="color: ${intToHexColor(activity.color)};">${activity.horaInicio} - ${activity.horaFin}</span><br>
                        <em style="color: ${intToHexColor(activity.color)};">${activity.nombreZona}</em>
                    `;

                    activityDiv.addEventListener('click', () => showActivityDetailsModal(activity));
                    dayCell.appendChild(activityDiv);
                });
            } else {
                dayCell.textContent = '';
            }

            row.appendChild(dayCell);
        });

        scheduleContainer.appendChild(row);
    });
}

function populateGroupSquares(scheduleData) {
    const groupContainer = document.getElementById('group-container');
    if (!groupContainer) return;

    // Limpia el contenedor
    groupContainer.innerHTML = '';

    // Obtener agrupaciones únicas con sus colores
    const groups = {};
    scheduleData.forEach(activity => {
        if (!groups[activity.agrupacion]) {
            groups[activity.agrupacion] = intToHexColor(activity.color);
        }
    });

        // Crear los cuadrados
        Object.entries(groups).forEach(([name, color]) => {
        const cleanName = name.startsWith('AADD ') ? name.replace('AADD ', '') : name;

        const square = document.createElement('div');
        square.style.width = 'auto';
        square.style.paddingLeft = '20px';
        square.style.paddingRight = '20px';
        square.style.height = '30px';
        square.style.backgroundColor = color;
        square.style.boxSizing = 'border-box';
        square.style.display = 'flex';
        square.style.alignItems = 'center';
        square.style.justifyContent = 'center';
        square.style.color = '#fff';
        square.style.borderRadius = '0px';
        square.style.fontWeight = 'regular';
        square.style.textAlign = 'center';
        square.title = name || 'Sin agrupación'; // Tooltip con el nombre

        square.textContent = `${cleanName || 'Sin agrupación'}  +`;
        square.addEventListener('click', () => showModal(cleanName, color));
        groupContainer.appendChild(square);
    });
}

function showActivityDetailsModal( activity ) {
    const modalActivityTitle = document.getElementById('modalActivityTitle');
    const modalActivityRoom = document.getElementById('modalActivityRoom');
    const modalActivityDate = document.getElementById('modalActivityDate');
    const modalActivityDescription = document.getElementById('modalActivityDescription');

    modalActivityTitle.textContent = `${activity.nombre}`;
    modalActivityTitle.style.color = intToHexColor(activity.color);
    modalActivityTitle.style.paddingTop = '5px';

    modalActivityRoom.textContent = `${activity.nombreZona}`;
    modalActivityRoom.style.paddingTop = '5px';

    modalActivityDate.textContent = `${ activity.dayWeek } | ${ activity.horaInicio } - ${ activity.horaFin }`;

    modalActivityDescription.textContent = `${activity.descripcion}`;
    modalActivityDescription.style.paddingTop = '5px';

    const modal = new bootstrap.Modal(document.getElementById('groupActivityModal'), {});
    modal.show();

}

function showModal(groupName, color) {
    const modalTitle = document.getElementById('modalTitle');
    const modalBody = document.getElementById('modalBody');

    switch( groupName ) {
        case 'ACUATICAS':
            modalBody.textContent = `Estas actividades están diseñadas para la mejora global del cuerpo. La dificultad para moverse en el agua facilita el desarrollo de la resistencia y tonificación muscular. Ideales también para comenzar a moverse y/o mejorar de alguna lesión.`;
            break;
        case 'TONIFICACION':
            modalBody.textContent = `La actividades de tonificación están diseñadas para trabajar el tono muscular de todo nuestro cuerpo, ayudando a nuestros músculos a estar más dibujados y firmes.`;
            break;
        case 'CUERPO/MENTE':
            modalBody.textContent = `Las actividades de cuerpo y mente están diseñadas para un trabajo de conexión integral de todo nuestro ser. Realizaremos prácticas para entrenar la mente y el cuerpo en una sola sintonía.`;
            break;
        case 'Genérica de actividades colectivas':
            modalBody.textContent = `Las actividades cardiovasculares están diseñadas para mejorar el sistema cardiovascular a través de ejercicios donde entrenamos la resistencia, de este modo musculamos el corazón, mejoramos la capacidad pulmonar y definimos nuestro cuerpo.`;
            break;
        case 'FITNESS':
            modalBody.textContent = `Las actividades de cuerpo y mente están diseñadas para un trabajo de conexión integral de todo nuestro ser. Realizaremos prácticas para entrenar la mente y el cuerpo en una sola sintonía.`;
            break;
        case 'CARDIOVASCULARES':
            modalBody.textContent = `Las actividades cardiovasculares están diseñadas para mejorar el sistema cardiovascular a través de ejercicios donde entrenamos la resistencia, de este modo musculamos el corazón, mejoramos la capacidad pulmonar y definimos nuestro cuerpo.`;
            break;
        case 'COREOGRAFIADAS':
            modalBody.textContent = `Estas actividades están vinculadas a las cardiovasculares ya que el objetivo físico es el mismo. La diferencia entre ambas es el componente de baile. En este tipo de clases las coreorafías siempre están presentes.`;
            break;
        default:
            break;
    }

    modalTitle.textContent = `${groupName}`;
    modalTitle.style.background = color;
    modalTitle.style.color = '#fff';
    modalTitle.style.padding = '5px';
    // modalBody.textContent = `Una experiencia que invita a experimentar, poner foco y mejorar tu calidad de vida.Pilates y yoga (hatha y kundalini) con los que conectarás el cuerpo y la mente. ${groupName}.`;

    const modal = new bootstrap.Modal(document.getElementById('groupModal'), {});
    modal.show();
}

function intToHexColor(colorInt) {
    const hex = (colorInt >>> 0).toString(16).padStart(8, '0');
    return `#${hex.slice(2)}`; // Elimina los dos primeros caracteres (canal alpha)
}

async function getMondayAndSunday(date) {
    // Crear una nueva instancia para evitar modificar el objeto de la fecha original
    const currentDate = new Date(date);

    // Día de la semana (0 = domingo, 1 = lunes, ..., 6 = sábado)
    const currentDay = currentDate.getDay();

    // Ajustar para que el lunes sea el primer día de la semana
    const dayAdjustment = currentDay === 0 ? -6 : 1 - currentDay; // Si es domingo, ir 6 días atrás

    // Calcular el lunes
    const monday = new Date(currentDate);
    monday.setDate(currentDate.getDate() + dayAdjustment);

    // Calcular el domingo (lunes + 6 días)
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    // Formatear fechas en formato YYYY-MM-DD
    const formatDateISO = (date) => date.toISOString().split('T')[0];

    // Función para formatear fechas en el formato deseado
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('es-ES', { month: 'long' }); // Nombre del mes en español
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')} de ${month} de ${year}`;
    };

    const startWeek = document.getElementById('startWeek');
    const endWeek = document.getElementById('endWeek');
    const startWeekMobile = document.getElementById('startWeekMobile');
    const endWeekMobile = document.getElementById('endWeekMobile');
    startWeek.textContent = formatDate(monday);
    endWeek.textContent = formatDate(sunday);
    startWeekMobile.textContent = formatDate(monday);
    endWeekMobile.textContent = formatDate(sunday);



    let numberMonday = formatDateISO(monday).split('-')[2];

    const mNumber = document.getElementById('mNumber');
    const tNumber = document.getElementById('tNumber');
    const wNumber = document.getElementById('wNumber');
    const thNumber = document.getElementById('thNumber');
    const frNumber = document.getElementById('frNumber');
    const satNumber = document.getElementById('satNumber');
    const sunNumber = document.getElementById('sunNumber');

    mNumber.textContent = +numberMonday;
    tNumber.textContent = +numberMonday + 1;
    wNumber.textContent = +numberMonday + 2;
    thNumber.textContent = +numberMonday + 3;
    frNumber.textContent = +numberMonday + 4;
    satNumber.textContent = +numberMonday + 5;
    sunNumber.textContent = +numberMonday + 6;


    return {
        monday: formatDate(monday),
        sunday: formatDate(sunday),
        mondayISO: formatDateISO(monday),
        sundayISO: formatDateISO(sunday)
    };

}

async function updateWeek(direction) {

    const daysToAdd = direction === 'next' ? 7 : -7;
    currentWeekDate.setDate(currentWeekDate.getDate() + daysToAdd);
    const week = await getMondayAndSunday(currentWeekDate);
    loadScheduleData(week, placeSelected);
}

const daysOfWeek = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];

function showSpinner() {
    document.getElementById('spinner-container').classList.remove('d-none');
    document.getElementById('spinner-container-mobile').classList.remove('d-none');
    document.getElementById('activities-container').classList.add('d-none');
    document.getElementById('schedule-container').classList.add('d-none');
    disableButtons(true);
}

function hideSpinner() {
    document.getElementById('spinner-container').classList.add('d-none');
    document.getElementById('spinner-container-mobile').classList.add('d-none');
    document.getElementById('activities-container').classList.remove('d-none');
    document.getElementById('schedule-container').classList.remove('d-none');
    disableButtons(false);
}

function disableButtons(disable) {
    document.querySelectorAll('#day-buttons button, #week-navigation button').forEach(button => {
        button.disabled = disable;
    });
}

function populateMobileSchedule(data) {
    // Organizar las actividades por día
    const scheduleByDay = {};
    daysOfWeek.forEach(day => scheduleByDay[day] = []);

    data.forEach(activity => {
        const startTime = new Date(activity.horaDeInicio.replace(/=>/g, ':'));
        const dayName = daysOfWeek[(startTime.getDay() === 0 ? 6 : startTime.getDay() - 1)];
        scheduleByDay[dayName].push(activity);
    });

    // Asignar eventos a los botones
    document.querySelectorAll('#day-buttons button').forEach(button => {
        const day = button.getAttribute('data-day');
        button.addEventListener('click', () => {
            selectDay(day, scheduleByDay);
            setActiveButton(button);
        });
    });

    // Mostrar actividades del lunes por defecto
    const defaultButton = document.querySelector('#day-buttons button[data-day="lunes"]');
    if (defaultButton) {
        setActiveButton(defaultButton);
        selectDay('lunes', scheduleByDay);
    }
}

function setActiveButton(activeButton) {
    document.querySelectorAll('#day-buttons button').forEach(button => {
        button.classList.remove('active');
    });
    activeButton.classList.add('active');
}

function selectDay(day, scheduleByDay) {
    const container = document.getElementById('activities-container');
    if (!scheduleByDay) return;

    // Limpiar contenido
    container.innerHTML = '';

    // Ordenar actividades por hora
    const activities = scheduleByDay[day] || [];
    activities.sort((a, b) => new Date(a.horaDeInicio) - new Date(b.horaDeInicio));

    // Crear filas para cada actividad
    activities.forEach(activity => {

        const startTime = new Date(activity.horaDeInicio.replace(/=>/g, ':')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const endTime = new Date(activity.horaDeFin.replace(/=>/g, ':')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        const correctedStartTime = activity.horaDeInicio ? activity.horaDeInicio.replace(/=>/g, ':') : null;
        const correctedEndTime = activity.horaDeFin ? activity.horaDeFin.replace(/=>/g, ':') : null;

        const startTimeMobileModal = correctedStartTime ? new Date(correctedStartTime) : null;
        const endTimeMobileModal = correctedEndTime ? new Date(correctedEndTime) : null;

        activity.dayWeek = daysOfWeek[(startTimeMobileModal.getDay() === 0 ? 6 : startTimeMobileModal.getDay() - 1)];
        activity.horaInicio = startTimeMobileModal ? startTimeMobileModal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';
        activity.horaFin = endTimeMobileModal ? endTimeMobileModal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';

        const row = document.createElement('div');
        row.className = 'activity-row';

        row.innerHTML = `
            <div class="activity-time">${startTime} - ${endTime}</div>
            <div class="activity-name" style="color: ${intToHexColor(activity.color)};">${activity.nombre}</div>
        `;
        row.addEventListener('click', () => showActivityDetailsModal(activity));
        container.appendChild(row);
    });

    // Mostrar mensaje si no hay actividades
    if (activities.length === 0) {
        container.innerHTML = '<p class="text-center text-l-green">No hay actividades para este día.</p>';
    }
}

async function downloadPdf() {

    datesPDFSelected.sundayISO = await addDaysToDate(datesPDFSelected.sundayISO, 1);

    axios.get(`/api/provis/collectiveclassespdf?installationid=${placeSelected}&startdatetime=${ datesPDFSelected.mondayISO }&endDatetime=${ datesPDFSelected.sundayISO }&placename=${ placeNameSelected }`, {
        responseType: 'blob', // Especificar que queremos la respuesta como Blob
    }) // Ajusta el endpoint según tu configuración
    .then(response => {

        // Crear un enlace temporal

        const URLObject = window.URL || window.webkitURL;
        const url = URLObject.createObjectURL(response.data);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'agenda.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar la URL creada
        URLObject.revokeObjectURL(url);
    })
    .catch(error => {
        console.error('Error al descargar el PDF:', error);
    })
    .finally(() => {
        // Ocultar el spinner y habilitar el botón
        const buttonMobile = document.getElementById('text-download-mobile');
        const spinnerMobile = document.getElementById('download-spinner-mobile');
        const button = document.getElementById('text-download');
        const spinner = document.getElementById('download-spinner');


        button.classList.remove('d-none');
        spinner.classList.add('d-none');
        buttonMobile.classList.remove('d-none');
        spinnerMobile.classList.add('d-none');
        disableButtons(false);
    });;

}

// document.getElementById('previous-week').addEventListener('click', () => updateWeek('previous'));
// document.getElementById('next-week').addEventListener('click', () => updateWeek('next'));
// document.getElementById('previous-week-mobile').addEventListener('click', () => updateWeek('previous'));
// document.getElementById('next-week-mobile').addEventListener('click', () => updateWeek('next'));

document.getElementById('previous-week').addEventListener('click', () => {
    showSpinner();
    updateWeek('previous');
});
document.getElementById('next-week').addEventListener('click', () => {
    showSpinner();
    updateWeek('next');
});

document.getElementById('previous-week-mobile').addEventListener('click', () => {
    showSpinner();
    updateWeek('previous');
});
document.getElementById('next-week-mobile').addEventListener('click', () => {
    showSpinner();
    updateWeek('next');
});

document.getElementById('download-schedule-pdf').addEventListener('click', () => {
    const button = document.getElementById('text-download');
    const spinner = document.getElementById('download-spinner');

    button.classList.add('d-none');
    spinner.classList.remove('d-none');
    disableButtons(true);
    downloadPdf();
});
document.getElementById('download-schedule-pdf-mobile').addEventListener('click', () => {
    const buttonMobile = document.getElementById('text-download-mobile');
    const spinnerMobile = document.getElementById('download-spinner-mobile');

    buttonMobile.classList.add('d-none');
    spinnerMobile.classList.remove('d-none');
    disableButtons(true);
    downloadPdf();
});

function addDaysToDate(dateString, days) {
    // Convertir la cadena al objeto Date
    const date = new Date(dateString);
    // Sumar los días
    date.setDate(date.getDate() + days);
    // Formatear la nueva fecha en formato YYYY-MM-DD
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mes en dos dígitos
    const day = date.getDate().toString().padStart(2, '0'); // Día en dos dígitos

    return `${year}-${month}-${day}`;
}
